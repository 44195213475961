import jwtDecode, {JwtPayload} from "jwt-decode";
import {objectToQuery} from "./helpers/ObjectToQuery";
import {ENVIRONMENT} from "./environment";

function dec2hex(dec: number): string {
    return dec.toString(16).padStart(2, "0")
}

function generateId(len: number): string {
    const arr = new Uint8Array((len || 40) / 2)
    window.crypto.getRandomValues(arr)
    return Array.from(arr, dec2hex).join('')
}


class Auth {
    private _access_token = localStorage.getItem("access_token");
    // FIXME: this doesnt work, the components obviously aren't getting notified on updates. possible fix could be Context.Provider for global state
    private _refresh_planned?: Date;
    private _refresh_token = localStorage.getItem("refresh_token");
    private _id_token = localStorage.getItem("id_token");
    private renewTimer: number | null = null;
    private _login = this.getAccessTokenIfPending().then(async () => {
        this.recoverRefreshTokenOrLogin()
        await this.recoverAccessTokenOrRefresh()
    })

    private _username?: string;

    get username() {
        return this._username
    }

    get refreshPlanned() {
        return this._refresh_planned;
    }

    private _roles: string[] = [];

    get roles() {
        return this._roles;
    }

    get accessToken(): string {
        if (!this._access_token) throw Error("unauthenticated");
        return this._access_token;
    }

    set accessToken(token: string) {
        if (!token) throw Error("invalid access token");
        localStorage.setItem("access_token", token);
        this._access_token = token
    }

    get refreshToken(): string {
        if (!this._refresh_token) throw Error("unauthenticated");
        return this._refresh_token;
    }

    set refreshToken(token: string) {
        if (!token) throw Error("invalid refresh token");
        localStorage.setItem("refresh_token", token)
        this._refresh_token = token
    }

    get idToken(): string {
        if (!this._id_token) throw new Error("unauthenticated");
        return this._id_token
    }

    set idToken(token: string) {
        if (!token) throw Error("invalid id token");
        localStorage.setItem("id_token", token);
        this._id_token = token;
    }

    get loggedIn() {
        return this._login;
    }

    async getAccessTokenIfPending() {
        const state = sessionStorage.getItem("state");
        if (state) {
            sessionStorage.removeItem("state");
            return this.postToken()
        }
    }

    nowInSeconds = () => Math.floor(Date.now() / 1000);

    recoverRefreshTokenOrLogin() {
        let refreshToken = !!this._refresh_token ? jwtDecode<JwtPayload>(this._refresh_token) : null;
        if (!refreshToken || (refreshToken.exp || 0) < this.nowInSeconds()) this.redirectToLogin();
    }

    redirectToLogin() {
        const state = generateId(12)
        sessionStorage.setItem("state", state);
        const params = {
            ...ENVIRONMENT.AUTH,
            state,
            response_type: "code",
            scope: "openid"
        }
        window.location.href = ENVIRONMENT.AUTH_URL + objectToQuery(params);
    }

    async recoverAccessTokenOrRefresh() {
        let accessToken = !!this._access_token ? jwtDecode<{ exp?: number, resource_access: { wommels: { roles: string[] } }, preferred_username: string }>(this._access_token) : null;
        if (!!accessToken?.exp && (accessToken?.exp || 0) + 30 > this.nowInSeconds()) {
            this.scheduleRenew({exp: accessToken.exp})
            this._roles = accessToken.resource_access?.wommels?.roles || []
            this._username = accessToken.preferred_username;
        } else {
            await this.refreshAccessToken()
        }
    }

    async refreshAccessToken() {
        const response = await fetch(ENVIRONMENT.TOKEN_URL, {
            method: "POST",
            body: new URLSearchParams({
                ...ENVIRONMENT.AUTH,
                grant_type: "refresh_token",
                refresh_token: this.refreshToken,
            })
        })
        await this.handleNewToken(response);
    }

    async logout() {
        localStorage.removeItem("access_token");
        localStorage.removeItem("id_token");
        localStorage.removeItem("refresh_token");
        window.location.href = ENVIRONMENT.LOGOUT_URL + '?' + new URLSearchParams({
            id_token_hint: this.idToken,
            post_logout_redirect_uri: ENVIRONMENT.AUTH.redirect_uri,
        }).toString()
    }

    private scheduleRenew({exp}: { exp: number }) {
        if (!exp) throw new Error("invalid renew scheduled");
        const renewTimestamp = ((exp - 30) * 1000);
        const renewDelta = renewTimestamp - Date.now();
        if (this.renewTimer) window.clearTimeout(this.renewTimer);
        this.renewTimer = window.setTimeout(() => this.refreshAccessToken(), renewDelta)
        this._refresh_planned = new Date(renewTimestamp);
    }

    private async postToken() {
        const params = new URLSearchParams(window.location.search.substr(1));
        const code = params.get("code");
        const code_verifier = params.get("session_state");
        if (!code) throw Error("invalid code");
        if (!code_verifier) throw Error("invalid code_verifier");

        const response = await fetch(ENVIRONMENT.TOKEN_URL, {
            method: "POST",
            body: new URLSearchParams({
                ...ENVIRONMENT.AUTH,
                grant_type: "authorization_code",
                response_type: "code",
                code,
                code_verifier
            })
        });
        await this.handleNewToken(response);
    }

    private async handleNewToken(tokenResponse: Response) {
        const {
            access_token,
            id_token,
            refresh_token
        }: { access_token: string, refresh_token: string, id_token: string } = await tokenResponse.json();
        this.accessToken = access_token;
        this.refreshToken = refresh_token
        this.idToken = id_token
        const accessToken = jwtDecode<{ exp: number, resource_access: { wommels: { roles: string[] } }, preferred_username: string }>(access_token);
        this.scheduleRenew({exp: accessToken.exp})
        this._roles = accessToken.resource_access?.wommels?.roles || []
        this._username = accessToken.preferred_username;
    }
}

export const auth = new Auth();

import "./navigation.css";
import {NavLink, useLocation} from "react-router-dom";
import {
    detectionsFictionSearchUrl,
    fetchesListUrl,
    novelsFictionListUrl,
    profilesProfileUrl
} from "../requests/urls.const";
import React, {useEffect, useState} from "react";
import {IoMenu} from "@react-icons/all-files/io5/IoMenu";


export const NavMenuItem = ({url, name, permission = true}: { url: string, name: any, permission?: boolean }) => {
    if (!permission) return null;
    return <NavLink className={'py-3 px-3 md:pl-12 md:pr-2.5 md:pr-4 '} activeClassName={'bg-gray-700'}
                    to={url}>{name}</NavLink>
}

export const NavMenu = (permissions: { [key: string]: boolean }) => {
    const [open, toggle] = useState(false);
    const location = useLocation();
    useEffect(() => () => toggle(false), [location, toggle])
    return <div className={'BurgerNavMenu'}>
        <button className={"font-larger h-full p-3"} onClick={() => toggle(!open)}><IoMenu
            title={open ? 'open menu' : 'close menu'}/></button>
        <div className={'bg-gray-900 flex flex-col ' + (open ? 'shown' : 'hidden')}>
            <NavMenuItem permission={permissions['novels-system']} url={fetchesListUrl()} name={'queue'}/>
            <NavMenuItem url={profilesProfileUrl()} name={'profile'}/>
            <NavMenuItem url={detectionsFictionSearchUrl()} name={'find'}/>
            <NavMenuItem url={novelsFictionListUrl('?orderBy=fiction_title')} name={'fictions'}/>
        </div>
    </div>
}


export const Navigation = ({resourceAccess, extraFields = null}: { resourceAccess: string[], extraFields: any }) => {
    const permissions: { [key: string]: boolean } = {};
    resourceAccess.forEach(r => permissions[r] = true);

    return <nav className={'flex Navigation bg-gray-900'}>
        <NavMenu/>
        <NavMenuItem url={novelsFictionListUrl('?orderBy=last_read')} name={'continue'}/>
        <NavMenuItem url={novelsFictionListUrl('?orderBy=last_published')} name={'latest'}/>
        <div className={"flex-grow sm:flex-grow-0 sm:flex-col flex justify-end sm:order-last  "}>
            {extraFields}
        </div>
    </nav>
}

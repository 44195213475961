import React, {useEffect, useState} from "react";
import {Link, useParams} from "react-router-dom";
import {DetectedFictionDetailResponse} from "../../requests/requests.interface";
import {FullTimeFormat} from "../../components/Timestamp";
import {novelsFictionDetailsUrl} from "../../requests/urls.const";
import {backend} from "../../requests/backend.service";
import {DetectedAuthorLink} from "../../components/DetectionLinks";
import {Spinner} from "../../components/spinner";
import {IoCheckmark} from "@react-icons/all-files/io5/IoCheckmark";
import {IoWarningOutline} from "@react-icons/all-files/io5/IoWarningOutline";
import {IoAddOutline} from "@react-icons/all-files/io5/IoAddOutline";

const InfoLine = (params: { description: string, value: any }) => <div className="flex">
    <span className="slight-offset-color font-smaller">{params.description}: </span>
    <span className="pl-4 flex-grow">{params.value}</span>
</div>

const ChapterIcon = ({
                         fiction_id,
                         chapter_id,
                         onClick
                     }: { fiction_id: number | null, chapter_id: number | null, onClick: CallableFunction }) => {
    if (fiction_id && !chapter_id) return <IoWarningOutline onClick={() => onClick()}/>
    if (fiction_id && chapter_id) return <IoCheckmark/>
    return <IoAddOutline/>
}

function DetectionFictionDetails() {
    let {detected_fiction_id} = useParams<{ detected_fiction_id: string }>();

    const [request, setRequest] = useState<DetectedFictionDetailResponse>();
    const [importing, setImporting] = useState<boolean>(false);
    const [showDescription, setShowDescription] = useState<boolean>(false)
    useEffect(() => {
        if (!request) {
            backend.loadDetectedFiction({detected_fiction_id})
                .then(data => setRequest(data))
                .catch(console.error)
        }
    })
    if (!request) return (<div>loading...</div>)
    const detectedFictionImportData = <div>
        <div className="slight-offset-color font-smaller text-center p-0.5">
            <button onClick={() => setShowDescription(!showDescription)}>toggle description</button>
        </div>
        {showDescription ? <div>{request.detected_fiction.fiction_description}</div> : null}
    </div>

    const doImport = () => {
        setImporting(true);
        backend.triggerFictionImport({detected_fiction_id})
            .then(data => {
                setRequest(data);
                setImporting(false);
            })
    }

    const importChapter = (chapterId: number, index: number) => {
        setImporting(true);
        backend.triggerChapterImport({detected_chapter_id: chapterId})
            .then((data) => {
                const dctrs = [...request.detected_chapters]
                dctrs[index] = data.detected_chapter;
                setRequest({...request, detected_chapters: dctrs})
                setImporting(false);
            })
    }


    const importPrompt = !!request?.detected_fiction.fiction_id
        ? <Link className={'bg-gray-900 px-2 py-1'} to={novelsFictionDetailsUrl(request.detected_fiction.fiction_id)}>fiction
            overview</Link>
        : <button onClick={doImport} disabled={importing} className={'btn'}>{importing ?
            <Spinner size={'24'}/> : 'import'}</button>

    return <div className="DetectionFictionDetails PageBody overflow-y-auto">
        <div className={"PageContent max-w-2xl w-full mx-auto"}>
            <div className="bg-secondary p-2 ">
                <h4 className="text-center">{request.detected_fiction.fiction_title}</h4>
                <InfoLine description={"source"}
                          value={<a href={request.detected_fiction.fiction_url || '#'} target={"_blank"}
                                    rel={"noreferrer noopener"}>{request.detected_fiction.source}</a>}/>
                <InfoLine description={"discovered"}
                          value={<FullTimeFormat dateString={request.detected_fiction.detected_ts}/>}/>
                <InfoLine description={"author"}
                          value={<DetectedAuthorLink detected_author={request.detected_author}/>}/>
                {detectedFictionImportData}
                <div className={'flex justify-center p-1'}>{importPrompt}</div>
            </div>
            <div className={"my-2 px-4 py-2 bg-secondary"}>
                {request.detected_chapters.map((c, index) => {
                    return <div key={c.detected_chapter_id} className={"py-1.5 flex items-center"}>
                        <span className={"flex-grow"}>{c.chapter_title}</span>
                        <button disabled={!request?.detected_fiction.fiction_id} className={"max-w-min"}>
                            <ChapterIcon fiction_id={request?.detected_fiction.fiction_id}
                                         chapter_id={c.chapter_id}
                                         onClick={() => importChapter(c.detected_chapter_id, index)}/>
                        </button>
                    </div>
                })}
            </div>
        </div>
    </div>;
}

export default DetectionFictionDetails;

import {
    detectionsAuthorDetailUrl,
    detectionsFictionDetailUrl,
    novelsAuthorDetailsUrl,
    novelsChapterDetailsUrl,
    novelsFictionDetailsUrl
} from "../requests/urls.const";
import {Link} from "react-router-dom";
import React from "react";

export const DetectedAuthorLink = ({
                                       className,
                                       detected_author
                                   }: { className?: string, detected_author: { author_name?: string, detected_author_id: number } | null }) => {
    if (!detected_author || !detected_author.author_name) return null;
    return <Link className={!className ? 'underline' : 'underline ' + className}
                 to={detectionsAuthorDetailUrl(detected_author.detected_author_id)}>{detected_author.author_name}</Link>
}

export const DetectedFictionLink = ({
                                        className,
                                        detected_fiction
                                    }: { className?: string, detected_fiction: { fiction_title?: string | null, detected_fiction_id?: number | null } | null }) => {
    if (!detected_fiction || !detected_fiction.fiction_title || !detected_fiction.detected_fiction_id) return null;
    return <Link className={!className ? 'underline' : 'underline ' + className}
                 to={detectionsFictionDetailUrl(detected_fiction.detected_fiction_id)}>{detected_fiction.fiction_title}</Link>
}

export const FictionLink = ({
                                className,
                                fiction
                            }: { className?: string, fiction: { fiction_title?: string, fiction_id?: number } }) => {
    if (!fiction.fiction_title || !fiction.fiction_id) return null;
    return <Link className={!className ? '' : ' ' + className}
                 to={novelsFictionDetailsUrl(fiction.fiction_id)}>{fiction.fiction_title}</Link>
}
export const ChapterLink = ({
                                className, chapter, prefix = null
                            }: { className?: string, chapter: { chapter_title?: string, chapter_id?: number }, prefix?: any }) => {
    if (!chapter.chapter_title || !chapter.chapter_id) return null;
    return <Link className={!className ? '' : ' ' + className}
                 to={novelsChapterDetailsUrl(chapter.chapter_id)}>{prefix}{chapter.chapter_title}</Link>
}

export const AuthorLink = ({
                               className,
                               author,
                               prefix = null,
                           }: { className?: string, author: { author_name?: string, author_id?: number }, prefix?: any }) => {
    if (!author.author_name || !author.author_id) return null;
    return <Link className={!className ? '' : ' ' + className}
                 to={novelsAuthorDetailsUrl(author.author_id)}>{prefix}{author.author_name}</Link>
}

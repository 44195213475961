import React, {useState} from "react";
import {DetectedFictionsDataListResponse} from "../../requests/requests.interface";
import {
    DetectedAuthorDataList,
    DetectedChapterDataList,
    DetectedFictionDataList
} from "../../components/ExtendedDetectedDataLists";
import {FullTimeFormat} from "../../components/Timestamp";
import {ColumnHeader} from "./ScrapesFetchesDetails";
import {IoCheckmark} from "@react-icons/all-files/io5/IoCheckmark";
import {IoRefresh} from "@react-icons/all-files/io5/IoRefresh";
import {IoWarning} from "@react-icons/all-files/io5/IoWarning";
import {ParserEnum} from "../../requests/Parser.enum";
import {backend} from "../../requests/backend.service";
import {Spinner} from "../../components/spinner";

function ParseSuccessIndicator(params: { success: boolean | null }) {
    if (params.success) return <IoCheckmark title="successfully parsed"/>
    if (params.success === null) return <IoRefresh title="parse pending"/>
    return <IoWarning title="parse failed"/>
}

function ScrapesParseDetails(props: { parse_id: string | number, parser: ParserEnum, success: boolean | null, processed: string }) {
    const [detections, setDetections] = useState<DetectedFictionsDataListResponse>()
    const [expanded, expand] = useState<boolean>(false);

    const loadDetails = () => {
        expand(true);
        backend.loadParseDetails({parse_id: props.parse_id})
            .then(data => setDetections(data))
            .catch(console.error)
    }

    const expansion =
        expanded
        ? detections
                ? <div>
                    <DetectedAuthorDataList detections={detections.detected_author_data}/>
                    <DetectedFictionDataList detections={detections.detected_fiction_data}/>
                    <DetectedChapterDataList detections={detections.detected_chapter_data}/>
                </div>
                : <div className={"h-full justify-center items-center flex"}><Spinner size={24}/></div>
        : <div className={"text-center"}>
            <button className={'bg-gray-800 px-2 py-1 rounded-l mx-auto'} onClick={loadDetails}>load details</button>
        </div>

    return <div className="bg-secondary my-2 max-w-2xl w-full">
        <table className="mb-4">
            <tbody>
            <tr>
                <ColumnHeader name={"type"}/>
                <td className={"text-l"}>{props.parser}</td>
            </tr>
            <tr>
                <ColumnHeader name="parse id"/>
                <td>{props.parse_id}</td>
            </tr>
            <tr>
                <ColumnHeader name="success"/>
                <td><ParseSuccessIndicator success={props.success}/></td>
            </tr>
            <tr>
                <ColumnHeader name="processed"/>
                <td><FullTimeFormat dateString={props.processed}/></td>
            </tr>
            </tbody>
        </table>
        {expansion}
    </div>
}

export default ScrapesParseDetails;

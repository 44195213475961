import {Link, useParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {DetectedAuthorDetailResponse} from "../../requests/requests.interface";
import {detectionsFictionDetailUrl, novelsAuthorDetailsUrl} from "../../requests/urls.const";
import {backend} from "../../requests/backend.service";


function DetectionsAuthorDetails() {
    let {detected_author_id} = useParams<{ detected_author_id: string }>();

    const [request, setRequest] = useState<DetectedAuthorDetailResponse>()

    useEffect(() => {
        if (!request) {
            backend.loadDetectedAuthor({detected_author_id})
                .then(data => setRequest(data))
                .catch(console.error)
        }
    })

    const doImport = () => {
        backend.triggerAuthorImport({detected_author_id})
            .then(data => setRequest(data))
            .catch(console.error)
    }

    const importPrompt = !!request?.detected_author.author_id
        ? <Link className={'bg-gray-900 px-2 py-1'} to={novelsAuthorDetailsUrl(request.detected_author.author_id)}>author
            overview</Link>
        : <button onClick={doImport} className={'bg-action px-4 py-1'}>import</button>

    if (!request) return (<div>loading...</div>)
    return <div className={'DetectionsAuthorDetails PageBody'}>
        <div className="rounded-l mx-auto my-2 pt-0 bg-secondary pb-2 max-w-2xl w-full PageHeader">
            <h4 className="text-center m-0 p-2">{request.detected_author.author_name}</h4>

            <div className={'text-center p-4'}>
                <h6 className={"text-gray-300"}>fictions</h6>
                <ul>
                    {request.detected_fictions.map(f => <li key={f.detected_fiction_id}>
                        <Link className={"underline"}
                              to={detectionsFictionDetailUrl(f.detected_fiction_id)}>{f.fiction_title}</Link>
                    </li>)}
                </ul>
            </div>
            <br />
            <div className={'flex justify-center'}>
                {importPrompt}
            </div>
        </div>
    </div>
}
export default DetectionsAuthorDetails;

import React, {useEffect, useState} from "react";
import {ScrapesFetchListResponse, ScrapesFetchPaginationResponse} from "../../requests/requests.interface";
import {Link, useLocation} from "react-router-dom";
import {TimeFormat} from "../../components/Timestamp";
import {fetchesDetailsUrl, fetchesListUrl} from "../../requests/urls.const";
import {objectToQuery} from "../../helpers/ObjectToQuery";
import {IoCheckmark} from "@react-icons/all-files/io5/IoCheckmark";
import {IoWarning} from "@react-icons/all-files/io5/IoWarning";
import "./ScrapesFetchesList.css";
import {FetcherEnum} from "../../requests/Fetcher.enum";
import {IoGlobe} from "@react-icons/all-files/io5/IoGlobe";
import {backend} from "../../requests/backend.service";
import {Pagination} from "../../components/pagination";

export function ScrapesFetchesStatusIndicator(params: { status: number | null }) {
    switch (params.status) {
        case null:
            return null;
        case 200:
            return <IoCheckmark title="successfully fetched"/>
        default:
            return <IoWarning title={`fetch failed with status ${params.status}`}/>
    }
}

export function ScrapesFetcherIndicator(params: { fetcher: FetcherEnum }) {
    switch (params.fetcher) {
        case FetcherEnum.GLOBAL:
            return <IoGlobe title="global fetcher"/>
        default:
            return <div>{params.fetcher}</div>;
    }
}

export function cardColor(status: number | null) {
    if (status === null) return 'default';
    switch (true) {
        case status === 200:
            return 'success'
        case status === 999:
            return 'default'
        case status > 500:
            return 'error'
        case status > 400:
            return 'warning'
        default:
            return 'default'
    }
}

const ScrapesFetchesPagination = ({date, open}: { date: string, open: boolean }) => {
    const [pagination, setPagination] = useState<ScrapesFetchPaginationResponse>()
    useEffect(() => {
        backend.loadScrapesFetchesPagination({date}).then(json => setPagination(json));
    }, [date])
    if (!pagination) return null
    return <ul
        className={open ? 'absolute sm:top-0 top-12 sm:left-32 w-48 bg-gray-900 p-4 z-10' : "hidden"}>
        <li className={"py-2 mb-4"}><Link to={fetchesListUrl()}>pending: {pagination.pending.queues}</Link></li>
        {pagination.months.map(m =>
            <li className={"py-2"} key={m.date}>
                <Link to={fetchesListUrl(`?timestamp=${m.timestamp}`)}>{m.date}: {m.queues}</Link>
            </li>)}
        <hr/>
        {pagination.years.map(y =>
            <li className={"py-2.5"} key={y.date}>
                <Link to={fetchesListUrl(`?timestamp=${y.timestamp}`)}>{y.date}: {y.queues}</Link>
            </li>
        )}
    </ul>;
}


function ScrapesFetchesList({setExtraFields}: { setExtraFields: CallableFunction }) {
    const [fetchesRequest, setFetchesRequest] = useState<ScrapesFetchListResponse>();
    const [openNav, setOpenNav] = useState<boolean>(false);

    const location = useLocation();

    useEffect(() => {
        if (!fetchesRequest) return
        const params = {
            forward: fetchesRequest?.fetches.length + fetchesRequest.offset < fetchesRequest.total_fetches
                ? objectToQuery({
                    timestamp: fetchesRequest.date,
                    offset: (fetchesRequest.offset + fetchesRequest.fetches.length).toString()
                }) : null,
            backward: !!fetchesRequest.offset
                ? objectToQuery({
                    timestamp: fetchesRequest.date,
                    offset: Math.max(fetchesRequest.offset - 50, 0).toString()
                })
                : null
        }
        setExtraFields(<div className={"flex sm:flex-col items-center align-center w-full"}>
            <div className={"flex-grow text-right py-2.5 px-1.5 sm:w-full h-full bg-gray-800 relative"}>
                <button className={"sm:w-28 text-center w-full"}
                        onClick={() => setOpenNav(!openNav)}>{fetchesRequest.total_fetches}</button>
                <ScrapesFetchesPagination open={openNav} date={fetchesRequest.date}/>
            </div>
            <Pagination
                next={params.forward}
                back={params.backward}
                destination={fetchesListUrl}/>
        </div>)
    }, [fetchesRequest, openNav, setOpenNav, setExtraFields])

    useEffect(() => {
        backend.loadScrapesFetches({query: location.search})
            .then(async (json) => {
                setFetchesRequest(json);
            })
        return () => {
            setExtraFields(null);
            setOpenNav(false);
        }
    }, [location, setExtraFields, setOpenNav])

    if (fetchesRequest?.fetches !== undefined) {
        return (
            <div className="PageBody">
                <div className="FetchesList PageContent">
                    {fetchesRequest.fetches.map((p) => {
                        return (
                            <Link className={"Fetch " + cardColor(p.status)}
                                  to={fetchesDetailsUrl(p.fetch_id)} key={p.fetch_id}>
                                <div className="header">
                                    <ScrapesFetcherIndicator fetcher={p.fetcher}/>
                                    <span className="flex-grow text-center">{p.fetched_ts === null
                                        ? ''
                                        : <TimeFormat dateString={p.fetched_ts}/>}</span>
                                    <ScrapesFetchesStatusIndicator status={p.status}/>
                                </div>
                                <div className={"whitespace-nowrap overflow-ellipsis overflow-hidden"}
                                     title={p.fiction_title || ''}>{p.fiction_title}</div>
                                <div className={"whitespace-nowrap overflow-ellipsis overflow-hidden"}
                                     title={p.chapter_title || ''}>{p.chapter_title}</div>
                                <div className="footer">
                                    <div className="parser">{p.parser}</div>
                                    <div className="queued">queued: <TimeFormat dateString={p.queued_ts}/></div>
                                </div>
                            </Link>
                        )
                    })}
                </div>
            </div>
        );
    }
    return <div>loading...</div>
}

export default ScrapesFetchesList;

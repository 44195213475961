import {Link} from "react-router-dom";
import {useEffect, useState} from "react";
import {IoCaretBack} from "@react-icons/all-files/io5/IoCaretBack";
import {IoCaretForward} from "@react-icons/all-files/io5/IoCaretForward";

export const Pagination = ({
                               next,
                               back,
                               destination
                           }: { next: string | null, back: string | null, destination: CallableFunction }) => {
    return <div className={"divide-x divide-black divide-opacity-40 sm:flex-col flex sm:w-full text-right"}>
        {back != null
            ? <Link className={"bg-action px-4 py-3 inline-block"} to={destination(back)}>back</Link>
            : <button className={"bg-gray-700 px-4 py-3 text-right"} disabled={true}>back</button>}
        {next != null
            ? <Link className={"bg-action px-4 py-3 inline-block text-right"} to={destination(next)}>next</Link>
            : <button className={"bg-gray-700 px-4 py-3 text-right"} disabled={true}>next</button>}
    </div>
}
export const ButtonPagination = ({next, back}: { next: CallableFunction | null, back: CallableFunction | null }) => {
    return <div className={"divide-x divide-black divide-opacity-40 sm:flex-col flex sm:w-full "}>
        {back != null
            ? <button className={"bg-action px-4 py-3 inline-block"} onClick={() => back()}>back</button>
            : <button className={"bg-gray-700 px-4 py-3 sm:w-full"} disabled={true}>back</button>}
        {next != null
            ? <button className={"bg-action px-4 py-3 inline-block"} onClick={() => next()}>next</button>
            : <button className={"bg-gray-700 px-4 py-3"} disabled={true}>next</button>}
    </div>
}
export const IndexPagination = ({total, current, activate, className = ''}: {
    total: number | null | undefined, current: number | null | undefined, activate: CallableFunction, className?: string
}) => {
    const [shownPages, setShownPages] = useState<number>(0)
    useEffect(() => {
        if (!total || !current) return
        setShownPages(Math.floor((current - 1) / 5) * 5)
    }, [current, total])
    if (!total || !current) return null;
    const allPages = new Array(total).fill(null).map(((e, i) => i + 1));

    return <div className={"flex " + className}>
        <button disabled={shownPages <= 0}
                className={"flex-grow bg-gray-800 disabled:bg-gray-900 disabled:cursor-not-allowed"}
                onClick={() => setShownPages(Math.max(0, shownPages - 5))}><IoCaretBack className={"mx-auto"}/></button>
        {allPages.slice(shownPages, Math.min(shownPages + 5, total)).map(p => <button
            className={(p === current ? "bg-gray-700" : "bg-gray-800") + " flex-grow"} key={p}
            onClick={() => activate(p)}>{p}</button>)}
        <button disabled={shownPages + 5 >= total}
                className={"flex-grow bg-gray-800 disabled:bg-gray-900 disabled:cursor-not-allowed"}
                onClick={() => setShownPages(Math.min(total - 5, shownPages + 5))}><IoCaretForward
            className={"mx-auto"}/></button>
    </div>
}

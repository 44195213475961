import React, {useEffect, useState} from "react";
import {Link, useParams} from "react-router-dom";
import {ScrapesFetchDetailResponse} from "../../requests/requests.interface";
import {FullTimeFormat} from "../../components/Timestamp";
import {fetchesDetailsUrl} from "../../requests/urls.const";
import {ScrapesFetcherIndicator, ScrapesFetchesStatusIndicator} from "./ScrapesFetchesList";
import ScrapesParseDetails from "./ScrapesParseDetails";
import {backend} from "../../requests/backend.service";
import {DetectedFictionLink} from "../../components/DetectionLinks";
import {Spinner} from "../../components/spinner";


function DetectionParseLink(params: { detection_fetch_id?: number | null }) {
    if (!params.detection_fetch_id) return null
    return <Link to={fetchesDetailsUrl(params.detection_fetch_id)} className="btn">detection</Link>
}

export const ColumnHeader = (params: { name: string }) => <td
    className="text-right px-8 slight-offset-color font-smaller whitespace-nowrap">{params.name}: </td>;

function ScrapesFetchesDetails() {
    let {fetch_id} = useParams<{ fetch_id: string }>();

    let [fetchRequest, setFetch] = useState<ScrapesFetchDetailResponse | null>(null);
    let [fetching, setFetching] = useState<boolean>(false);

    useEffect(() => {
        backend.loadScrapeFetchDetail({fetch_id})
            .then((json) => setFetch(json))
            .catch(console.error)
    }, [fetch_id])

    const forceFetch = () => {
        setFetching(true);
        backend.triggerFetch({fetch_id})
            .then((json) => {
                setFetch(json)
                setFetching(false)
            })
            .catch(console.error)
    }

    if (fetchRequest) {
        return (
            <div className={"PageBody"}>
                <div className='FetchDetail card container mx-auto my-2 max-w-md PageHeader'>
                    <table className="w-full">
                        <tbody>
                        <tr>
                            <ColumnHeader name="fetch id"/>
                            <td>{fetchRequest.fetch.fetch_id}</td>
                        </tr>
                        <tr>
                            <ColumnHeader name="parser"/>
                            <td>{fetchRequest.fetch.parser}</td>
                        </tr>
                        <tr>
                            <ColumnHeader name={"fetcher"}/>
                            <td className={"flex items-center"}>
                                <ScrapesFetcherIndicator fetcher={fetchRequest.fetch.fetcher}/>
                                <span className={"ml-1"}>{fetchRequest.fetch.fetcher}</span>

                            </td>
                        </tr>
                        <tr>
                            <ColumnHeader name="status code"/>
                            <td className={"flex items-center"}>
                                <ScrapesFetchesStatusIndicator status={fetchRequest.fetch.status}/>
                                <span className={"ml-1"}>{fetchRequest.fetch.status}</span>
                            </td>
                        </tr>
                        <tr>
                            <ColumnHeader name="body exists"/>
                            <td>{String(fetchRequest.fetch.body_exists)}</td>
                        </tr>
                        <tr>
                            <ColumnHeader name="queued on"/>
                            <td colSpan={3}><FullTimeFormat dateString={fetchRequest.fetch.queued_ts}/></td>
                        </tr>
                        <tr>
                            <ColumnHeader name="fetched on"/>
                            <td colSpan={3}><FullTimeFormat dateString={fetchRequest.fetch.fetched_ts}/></td>
                        </tr>
                        <tr>
                            <ColumnHeader name={"detected fiction"}/>
                            <td>
                                <DetectedFictionLink detected_fiction={fetchRequest.fetch}/>
                            </td>
                        </tr>
                        <tr>
                            <ColumnHeader name="url"/>
                            <td colSpan={3}>
                                <a className="underline" target="_blank" rel="noopener noreferrer"
                                   href={fetchRequest.fetch.url}>source</a>
                            </td>
                        </tr>

                        <tr>
                            <td colSpan={4}>
                                <div className="flex justify-around">
                                    <DetectionParseLink detection_fetch_id={fetchRequest.fetch.detection_fetch_id}/>
                                    <button className="btn disabled:cursor-not-allowed"
                                       //     disabled={fetching || fetchRequest.fetch.body_exists}
                                            onClick={forceFetch}>{fetching ?
                                        <Spinner size={24}/> : 'force fetch'} </button>
                                </div>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
                <div className={"PageContent max-w-2xl mx-auto w-full"}>
                    {fetchRequest.parses !== null ? fetchRequest.parses?.map(p => {
                        return (
                            <div key={p.parse_id} className="flex justify-center flex-wrap">
                                <ScrapesParseDetails parse_id={p.parse_id} parser={p.parser} processed={p.processed_ts}
                                                     success={p.success}/>
                            </div>
                        )
                    }) : <div>no fetches</div>}
                </div>
            </div>
        )
    }
    return (<div>loading...</div>)
}

export default ScrapesFetchesDetails;

import {ENVIRONMENT} from "../environment";
import {
    DetectedAuthorDetailResponse,
    DetectedChapterImportResponse,
    DetectedFictionDetailResponse,
    DetectedFictionsDataListResponse,
    DetectedFictionSearchRequest,
    DetectedFictionSearchResponse,
    NovelsChapterDetailResponse,
    NovelsChaptersByFictionIdResponse,
    NovelsFictionDetailsResponse,
    NovelsFictionListResponse,
    ScrapesFetchDetailResponse,
    ScrapesFetchListResponse, ScrapesFetchPaginationResponse
} from "./requests.interface";
import {auth} from "../auth";

const get = (url: string, opts: any = {}) => {
    return fetch(url, {
        ...opts,
        headers: {
            ...(opts.headers || {}),
            "Authorization": `Bearer ${auth.accessToken}`
        },
    });
}

const post = (url: string, body: any, opts: any = {}) => {
    return fetch(url, {
        ...opts,
        method: 'POST',
        headers: {
            ...(opts.headers || {}),
            "Authorization": `Bearer ${auth.accessToken}`,
            "Content-Type": "Application/json;charset=UTF-8"
        },
        body: JSON.stringify(body),
    });
}

const handleForbidden = async (res: Response, retry: { fn: any, args: { attempt: number, [key: string]: any } }) => {
    if (res.status === 200 || res.status === 201 || res.status === 202 || res.status === 203) return await res.json();
    else if (res.status === 204) return res;

    if (res.status === 403 || res.status === 401) {
        const keycloak = auth;
        await keycloak.refreshAccessToken().catch(() => keycloak.redirectToLogin());
        if (retry.args.attempt < 2) {
            return retry.fn(retry.args);
        }
    }
    throw res;
}

class BackendService {
    async loadParseDetails({parse_id, attempt = 0}: {
        parse_id: number | string, attempt?: number
    }): Promise<DetectedFictionsDataListResponse> {
        return get(`${ENVIRONMENT.API}/scrapes/parses/${parse_id}/detections`)
            .then(e => handleForbidden(e, {
                fn: this.loadParseDetails,
                args: {parse_id, attempt: attempt + 1}
            }))
    }

    async loadScrapesFetches({query, attempt = 0}: {
        query: string, attempt?: number
    }): Promise<ScrapesFetchListResponse> {
        return get(`${ENVIRONMENT.API}/scrapes/fetches${query}`).then(e => handleForbidden(e, {
            fn: this.loadScrapesFetches,
            args: {query, attempt: attempt + 1}
        }));
    }

    async loadScrapesFetchesPagination({date, attempt = 0}: {
        date: string, attempt?: number
    }): Promise<ScrapesFetchPaginationResponse> {
        return get(`${ENVIRONMENT.API}/scrapes/fetches/pagination?date=${date}`).then(e => handleForbidden(e, {
            fn: this.loadScrapesFetches,
            args: {date, attempt: attempt + 1}
        }));
    }

    async loadNovelsFictionList({
                                    query,
                                    attempt = 0
                                }: { query: string, attempt?: number }): Promise<NovelsFictionListResponse> {
        return get(`${ENVIRONMENT.API}/novels/fictions${query}`)
            .then(e => handleForbidden(e, {fn: this.loadNovelsFictionList, args: {query, attempt: attempt + 1}}))
    }

    async loadNovelsFictionDetail({fiction_id, attempt = 0}: {
        fiction_id: number | string, attempt?: number
    }): Promise<NovelsFictionDetailsResponse> {
        return get(`${ENVIRONMENT.API}/novels/fictions/${fiction_id}`).then(e => handleForbidden(e, {
            fn: this.loadNovelsFictionDetail,
            args: {fiction_id, attempt: attempt + 1}
        }));
    }

    async loadNovelsChapterDetail({chapter_id, attempt = 0}: {
        chapter_id: number | string, attempt?: number
    }): Promise<NovelsChapterDetailResponse> {
        return get(`${ENVIRONMENT.API}/novels/chapters/${chapter_id}`).then(e => handleForbidden(e, {
            fn: this.loadNovelsChapterDetail,
            args: {chapter_id, attempt: attempt + 1}
        }));
    }

    async loadNovelsFictionChapters({fiction_id, attempt = 0}: {
        fiction_id: number | string, attempt?: number
    }): Promise<NovelsChaptersByFictionIdResponse> {
        return get(`${ENVIRONMENT.API}/novels/fictions/${fiction_id}/chapters`).then(e => handleForbidden(e, {
            fn: this.loadNovelsFictionChapters,
            args: {fiction_id, attempt: attempt + 1}
        }));
    }


    async loadScrapeFetchDetail({fetch_id, attempt = 0}: {
        fetch_id: number | string, attempt?: number
    }): Promise<ScrapesFetchDetailResponse> {
        return get(`${ENVIRONMENT.API}/scrapes/fetches/${fetch_id}`).then(e => handleForbidden(e, {
            fn: this.loadScrapeFetchDetail,
            args: {fetch_id, attempt: attempt + 1}
        }));
    }


    async triggerFetch({fetch_id, attempt = 0}: {
        fetch_id: number | string, attempt?: number
    }): Promise<ScrapesFetchDetailResponse> {
        return post(`${ENVIRONMENT.API}/scrapes/fetches/${fetch_id}`, {}).then(e => handleForbidden(e, {
            fn: this.triggerFetch,
            args: {fetch_id, attempt: attempt + 1}
        }));
    }

    async loadDetectedFiction({detected_fiction_id, attempt = 0}: {
        detected_fiction_id: string | number, attempt?: number
    }): Promise<DetectedFictionDetailResponse> {
        return get(`${ENVIRONMENT.API}/detections/fiction/${detected_fiction_id}`).then(e => handleForbidden(e, {
            fn: this.loadDetectedFiction,
            args: {detected_fiction_id, attempt: attempt + 1}
        }));
    }

    async triggerFictionImport({detected_fiction_id, attempt = 0}: {
        detected_fiction_id: number | string, attempt?: number
    }): Promise<DetectedFictionDetailResponse> {
        return post(`${ENVIRONMENT.API}/detections/fiction/${detected_fiction_id}`, {}).then(e => handleForbidden(e, {
            fn: this.triggerFictionImport,
            args: {detected_fiction_id, attempt: attempt + 1}
        }));
    }

    async triggerChapterImport({detected_chapter_id, attempt = 0}: {
        detected_chapter_id: number | string, attempt?: number
    }): Promise<DetectedChapterImportResponse> {
        return post(`${ENVIRONMENT.API}/detections/chapter/${detected_chapter_id}`, {}).then(e => handleForbidden(e, {
            fn: this.triggerChapterImport,
            args: {detected_chapter_id, attempt: attempt + 1}
        }));
    }

    async loadDetectedAuthor({detected_author_id, attempt = 0}: {
        detected_author_id: string | number, attempt?: number
    }): Promise<DetectedAuthorDetailResponse> {
        return get(`${ENVIRONMENT.API}/detections/author/${detected_author_id}`).then(e => handleForbidden(e, {
            fn: this.loadDetectedAuthor,
            args: {detected_author_id, attempt: attempt + 1}
        }));
    }

    async triggerAuthorImport({detected_author_id, attempt = 0}: {
        detected_author_id: number | string, attempt?: number
    }): Promise<DetectedAuthorDetailResponse> {
        return post(`${ENVIRONMENT.API}/detections/author/${detected_author_id}`, {}).then(e => handleForbidden(e, {
            fn: this.triggerAuthorImport,
            args: {detected_author_id, attempt: attempt + 1}
        }));
    }

    async searchDetectedFiction(request: DetectedFictionSearchRequest): Promise<DetectedFictionSearchResponse> {
        return post(`${ENVIRONMENT.API}/detections/fiction/search`, request).then(e => handleForbidden(e, {
            fn: this.searchDetectedFiction,
            args: {...request, attempt: (request.attempt || 0) + 1}
        }));
    }

    async reportReadingProgress({readingProgress, chapterId, attempt = 0}: {
        chapterId: string | number, readingProgress: number, attempt?: number
    }): Promise<{ chapter_id: number, reading_progress: number }> {
        return post(`${ENVIRONMENT.API}/progress`, {
            reading_progress: readingProgress, chapter_id: chapterId
        }).then(e => handleForbidden(e, {
            fn: this.reportReadingProgress,
            args: {readingProgress, chapterId, attempt: attempt + 1}
        }));
    }

    async submitExport({attempt = 0, parsedImport}: { attempt?: number, parsedImport: any }) {
        return post(`${ENVIRONMENT.API}/import`, parsedImport)
    }
}

export const backend = new BackendService();

import "./ProfilesProfile.css";
import {auth} from "../auth";
import {dateFormatFull} from "../components/Timestamp";
import React from "react";

export const ProfilesProfilePage = () => {

    return <div className={"bg-gray-700 rounded p-5 text-center mx-auto"}>
        <h4 className={"mb-4"}>Hey there, {auth.username} </h4>
        <div>refreshing token at {dateFormatFull(auth.refreshPlanned?.getTime() || 0)}</div>
        <ul>
            {auth.roles.map(r => <li key={r}>{r}</li>)}
        </ul>
        <hr/>
        <button className={"rounded-l bg-action p-2.5"} onClick={() => auth.logout()}>logout</button>
    </div>
}

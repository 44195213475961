import {useEffect, useRef, useState} from "react";
import {DetectedFictionSearchResponse, DetectedFictionSearchResult} from "../../requests/requests.interface";
import {backend} from "../../requests/backend.service";
import {throttle} from "lodash";
import {IoRefresh} from "@react-icons/all-files/io5/IoRefresh";
import {DetectedAuthorLink, DetectedFictionLink} from "../../components/DetectionLinks";

const DetectedFictionSearchResultsHeader = ({category, searchTerm}: { category: string, searchTerm: string }) =>
    <h4 className={"p-2 text-center bg-gray-700 rounded-t-lg"}>
        <span className={"text-md text-gray-200 mr-4"}>{category}: </span><span>{searchTerm}</span>
    </h4>

const DetectedFictionSearchResultsFiction = ({fiction: {detected_fiction_id, fiction_id, fiction_title, detected_author_id, author_name, similarity}}: {fiction: DetectedFictionSearchResult}) => <div className={"odd:bg-gray-800 bg-gray-900"}>
    <h6 className={"flex p-2"}>
        <div className={"pr-4 text-right min-w-min w-18 whitespace-nowrap"}>{Math.floor(similarity * 100)} %</div>
        <div className={"flex-grow"}>
            <DetectedFictionLink className={"py-2"} detected_fiction={{fiction_title, detected_fiction_id}} />
            <span className={"text-gray-300 text-sm whitespace-nowrap"}> by <DetectedAuthorLink className={"py-2"}  detected_author={{author_name, detected_author_id}} /></span>
        </div>
    </h6>
</div>

const DetectedFictionSearchResults = ({
                                          hits,
                                          pending,
                                          className = '',
                                      }: { hits: DetectedFictionSearchResponse | null, pending: boolean, className?: string }) => {
    if (!hits || !hits.results) return null;
    let header;
    if (!!hits.fiction_title) header =
        <DetectedFictionSearchResultsHeader category={"fiction"} searchTerm={hits.fiction_title}/>
    if (!!hits.author_name) header =
        <DetectedFictionSearchResultsHeader category={"author"} searchTerm={hits.author_name}/>

    return <div className={"my-4 DetectedFictionSearchResults flex flex-col " + className}>
        {header}
        <div className={"flex-grow overflow-y-auto"}>
            {hits.results.map(r => <DetectedFictionSearchResultsFiction key={r.detected_fiction_id} fiction={r}/>)}
        </div>
    </div>;
}


const SearchBox = ({
                       setHits,
                       setPending,
                       pending,
                       className = ''
                   }: { setHits: CallableFunction, setPending: CallableFunction, pending: boolean, className?: string }) => {
    const [authorName, setAuthorName] = useState<string>('');
    const [authorDirty, setAuthorDirty] = useState<boolean>(false);
    const [fictionTitle, setFictionTitle] = useState<string>('');
    const [fictionDirty, setFictionDirty] = useState<boolean>(false);
    const [activeSearch, activateSearch] = useState<number>(0);


    const forceFictionSearch = () => {
        debouncedSearch.current({fiction_title: fictionTitle});
        debouncedSearch.current.flush();
    }

    const handleFictionEnter = async (event?: any) => {
        if (event?.key === 'Enter') forceFictionSearch()
    }

    const forceAuthorSearch = () => {
        debouncedSearch.current({author_name: authorName})
        debouncedSearch.current.flush();
    }

    const handleAuthorEnter = async (event?: any) => {
        if (event?.key === 'Enter') forceAuthorSearch();
    }

    const debouncedSearch = useRef(throttle((searchBody: { fiction_title?: string, author_name?: string }) => {
        setPending(true)
        backend.searchDetectedFiction(searchBody)
            .then(serverResponse => {
                setHits(serverResponse);
                setPending(false);
                if (!!serverResponse.fiction_title && serverResponse.fiction_title === searchBody?.fiction_title) {
                    setFictionDirty(false);
                } else if (!!serverResponse.author_name && serverResponse.author_name === searchBody?.author_name) {
                    setAuthorDirty(false);
                }
            })
    }, 1000, {trailing: true, leading: false}))

    const handleFictionChange = (e: any) => {
        if (e.target.value !== fictionTitle) {
            if (!fictionDirty) setFictionDirty(true);
            setFictionTitle(e.target.value);
        }
    }
    const handleAuthorChange = (e: any) => {
        if (e.target.value !== authorName) {
            if (!authorDirty) setAuthorDirty(true);
            setAuthorName(e.target.value);
        }
    }
    useEffect(() => {
        const search = debouncedSearch.current;
        debouncedSearch.current({fiction_title: fictionTitle})
        return () => search.cancel();
    }, [fictionDirty, fictionTitle])
    useEffect(() => {
        const search = debouncedSearch.current;
        debouncedSearch.current({author_name: authorName})
        return () => search.cancel();
    },[authorDirty, authorName])

    return <div className={"SearchBox flex flex-col " + className}>
        <div className={"flex text-center"}>
            <label
                className={"cursor-pointer rounded-tl-xl flex-grow py-3  " + (activeSearch === 0 ? "bg-gray-700" : "bg-gray-900")}
                onClick={() => activateSearch(0)} htmlFor={"fiction_search_input"}>fiction title <IoRefresh
                className={fictionDirty ? "inline-block" : "hidden"}/></label>
            <label
                className={"cursor-pointer rounded-tr-xl flex-grow py-3 " + (activeSearch === 1 ? "bg-gray-700" : "bg-gray-900")}
                onClick={() => activateSearch(1)} htmlFor={"author_search_input"}>author name <IoRefresh
                className={authorDirty ? "inline-block" : "hidden"}/></label>
        </div>
        <div className="FictionSearch flex flex-col md:flex-row">
            <input id={"fiction_search_input"}
                   className={"w-full px-4 py-2 bg-black " + (activeSearch === 0 ? 'shown' : 'hidden')}
                   value={fictionTitle} onChange={handleFictionChange}
                   onKeyPress={handleFictionEnter}/>
            <input id={"author_search_input"}
                   className={"w-full px-4 py-2 bg-black " + (activeSearch === 1 ? 'shown' : 'hidden')}
                   value={authorName} onChange={handleAuthorChange}
                   onKeyPress={handleAuthorEnter}/>
            <button className={"bg-action px-4 py-2 mt-0.5 md:mt-0 md:ml-0.5 disabled:opacity-70 disabled:cursor-not-allowed "}
                    disabled={!fictionDirty && !authorDirty}
                    onClick={() => activeSearch === 0 ? forceFictionSearch() : forceAuthorSearch()}>search
            </button>
        </div>
    </div>
}

export const DetectionsFictionSearchPage = () => {
    const [hits, setHits] = useState<DetectedFictionSearchResponse | null>(null);
    const [pending, setPending] = useState<boolean>(false);

    return <div className={"DetectionsFictionSearch PageBody"}>
        <SearchBox className={"PageHeader max-w-2xl w-full mx-auto"} setHits={setHits} setPending={setPending}
                   pending={pending}/>
        <DetectedFictionSearchResults className={"PageContent max-w-2xl w-full mx-auto "} hits={hits}
                                      pending={pending}/>
    </div>

}

export const locale = navigator.language.startsWith('de') ? 'de' : 'en';

const dayFormat = new Intl.DateTimeFormat(locale, {dateStyle: "short"});
const fullFormat = new Intl.DateTimeFormat(locale, {dateStyle: "short", timeStyle: "short"});
const hourFormat = new Intl.DateTimeFormat(locale, {timeStyle: "short"})

export const strDateFormatDAY = (dateString: string) => dayFormat.format(Date.parse(dateString));
export const strDateFormatFull = (dateString: string) => fullFormat.format(Date.parse(dateString));
export const dateFormatDAY = (date: number) => dayFormat.format(date);
export const dateFormatHours = (date: number) => hourFormat.format(date);
export const dateFormatFull = (date: number) => fullFormat.format(date);

export const yesterday = (() => {
    const yd = new Date();
    yd.setDate(yd.getDate() - 1);
    return yd;
})();
export const lastWeek = (() => {
    const lw = new Date();
    lw.setDate(lw.getDate() - 7);
    return lw
})();

export function TimeFormat(props: {dateString: string}) {
    const date = Date.parse(props.dateString);
    const dateString = (() => {
        switch(true) {
            case date > yesterday.getTime(): return dateFormatHours(date);
            case date > lastWeek.getTime(): return dateFormatFull(date)
            default: return dateFormatDAY(date);
        }
    })();
    return <time title={dateFormatFull(date)}>{dateString}</time>
}

export function FullTimeFormat(props: {dateString: string|null}) {
    if(props.dateString === null) return null;
    const date = Date.parse(props.dateString);
    return <time title={dateFormatFull(date)}>{dateFormatFull(date)}</time>

}

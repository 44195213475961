const styleIt = (t: HTMLElement, w: number): void => {
    if (w < t.scrollWidth) {
        const rel = Number((w / t.scrollWidth).toFixed(2));
        const newWidth = Math.floor(t.scrollWidth * rel);
        const newHeight = Math.floor(t.offsetHeight * rel);
        const tooMuchWidth = Math.floor((t.scrollWidth - newWidth) / 2) - 5;
        const tooMuchHeight = Math.floor((t.scrollHeight - newHeight) / 2) - 10;
        t.style.transform = `scale(${rel})`;
        t.style.marginLeft = `-${tooMuchWidth}px`;
        t.style.marginRight = `-${tooMuchWidth}px`;
        t.style.marginTop = `-${tooMuchHeight}px`;
        t.style.marginBottom = `-${tooMuchHeight}px`;
    }
}

export const styleTables = () => {
    let skip = 0;
    const contentWidth = (document as any).querySelector('article').scrollWidth - 7;
    document.querySelectorAll('table').forEach((el) => {
        if (skip > 0) {
            --skip;
            return;
        }
        styleIt(el, contentWidth);
        skip = el.querySelectorAll('table').length;
    });
}

import React, {useEffect, useState} from 'react';
import ScrapesFetchesList from './routes/scrapes/ScrapesFetchesList';
import './App.css';
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import ScrapesFetchesDetails from "./routes/scrapes/ScrapesFetchesDetails";
import DetectionFictionDetails from "./routes/detections/DetectionsFictionDetails";
import DetectionsAuthorDetails from "./routes/detections/DetectionsAuthorDetails";
import {
    detectionsAuthorDetailUrl,
    detectionsFictionDetailUrl,
    detectionsFictionSearchUrl,
    fetchesDetailsUrl,
    fetchesListUrl,
    novelsChapterDetailsUrl,
    novelsFictionDetailsUrl,
    novelsFictionListUrl,
    profilesProfileUrl
} from "./requests/urls.const";
import {ProfilesProfilePage} from "./routes/ProfilesProfile";
import {Navigation} from "./components/navigation";
import {DetectionsFictionSearchPage} from "./routes/detections/DetectionsFictionSearchPage";
import {NovelsFictionList} from "./routes/novels/NovelsFictionList";
import {NovelsFictionDetail} from "./routes/novels/NovelsFictionDetail";
import {NovelsChapterDetail} from "./routes/novels/NovelsChapterDetails";
import {auth} from "./auth";

function App() {
    const [user, setUser] = useState<string>()
    const [roles, setRoles] = useState<string[]>([])
    useEffect(() => {
        auth.loggedIn.then(() => {
            setUser(auth.username)
            setRoles(auth.roles)
        })
    }, [])

    const [extraFields, setExtraFields] = useState(null);

    if (!user) return <p className={"flex max-w-l mx-auto h-screen justify-center items-center"}>
        <span className={"flex-grow"}>you need to be authenticated in order to use this application</span>
    </p>

    return (
        <Router>
            <Navigation resourceAccess={roles}
                        extraFields={extraFields}/>
            <Switch>
                <Route path={detectionsFictionSearchUrl()}>
                    <DetectionsFictionSearchPage/>
                </Route>

                <Route path={detectionsFictionDetailUrl(":detected_fiction_id")}>
                    <DetectionFictionDetails/>
                </Route>
                <Route path={detectionsAuthorDetailUrl(":detected_author_id")}>
                    <DetectionsAuthorDetails/>
                </Route>
                <Route path={fetchesDetailsUrl(":fetch_id")}>
                    <ScrapesFetchesDetails/>
                </Route>
                <Route path={fetchesListUrl()}>
                    <ScrapesFetchesList setExtraFields={setExtraFields}/>
                </Route>
                <Route path={profilesProfileUrl()}>
                    <ProfilesProfilePage/>
                </Route>
                <Route path={novelsFictionDetailsUrl(":fiction_id")}>
                    <NovelsFictionDetail setExtraFields={setExtraFields}/>
                </Route>
                <Route path={novelsFictionListUrl()}>
                    <NovelsFictionList setExtraFields={setExtraFields}/>
                </Route>
                <Route path={novelsChapterDetailsUrl(":chapter_id")}>
                    <NovelsChapterDetail setExtraFields={setExtraFields}/>
                </Route>
            </Switch>
        </Router>
    );
}

export default App;

import {Link, useParams} from "react-router-dom";
import React, {useEffect, useRef, useState} from "react";
import {ExtendedNovelsChapter, NovelsChapterDetailResponse, PrevNextChapter,} from "../../requests/requests.interface";
import {backend} from "../../requests/backend.service";
import {AuthorLink, FictionLink} from "../../components/DetectionLinks";
import {IoBook} from "@react-icons/all-files/io5/IoBook";
import {getScrollPosition, restorePosition} from "../../helpers/ScrollPosition";
import {IoCaretForwardOutline} from "@react-icons/all-files/io5/IoCaretForwardOutline";
import {IoCaretBackOutline} from "@react-icons/all-files/io5/IoCaretBackOutline";
import {novelsChapterDetailsUrl} from "../../requests/urls.const";
import {IoCaretUp} from "@react-icons/all-files/io5/IoCaretUp";
import {IoCaretDown} from "@react-icons/all-files/io5/IoCaretDown";
import {NovelsChapterList} from "./NovelsFictionDetail";
import {strDateFormatFull} from "../../components/Timestamp";
import {styleTables} from "../../helpers/scale-tables";

export const ChapterInfo = ({
                                chapter, previous, next
                            }: { chapter: ExtendedNovelsChapter, previous?: PrevNextChapter | null, next?: PrevNextChapter | null }) =>
    <div
        className={"md:py-4 text-center select-none"}>
        <h2>
            <FictionLink className={"py-2 px-4 inline-block w-full sm:w-auto bg-action sm:mr-1"}
                         fiction={chapter}/><
            AuthorLink prefix={"by "}
                       className={"py-2 px-4  bg-action sm:ml-1 whitespace-nowrap w-full sm:w-auto inline-block mt-0.5 sm:mt-0"}
                       author={chapter}/>
        </h2>
        <div className={"flex flex-col py-4"}>
            {previous
                ? <Link
                    className={'py-2 bg-gray-900 flex justify-center items-center underline px-2 ' + (previous.reading_progress ? 'bg-green-900' : '')}
                    to={novelsChapterDetailsUrl(previous.chapter_id)}>
                    <IoCaretBackOutline className={"w-8"}/>
                    <span className={"flex-grow"}>{previous.chapter_title}</span>
                </Link>
                : null}
            <h1 className={"mt-3"}><span
                className={"py-2 bg-gray-700 px-4 w-full inline-block"}>{chapter.chapter_title}</span>
            </h1>
            {!previous && !next ? <div className={"text-sm text-gray-300 mt-1"}><span>published: </span>
                <time>{strDateFormatFull(chapter.published_ts)}</time>
            </div> : null}
            {next
                ? <Link
                    className={'py-2 bg-gray-900 flex justify-center items-center underline px-2 ' + (next.reading_progress ? 'bg-green-900' : '')}
                    to={novelsChapterDetailsUrl(next.chapter_id)}>
                    <IoCaretForwardOutline className={"w-8"}/>
                    <span className={"flex-grow"}>{next.chapter_title}</span>
                </Link>
                : null}
        </div>
    </div>

export const InfoPopover = ({show, chapter, previous, next, synced = null, progress = null}: {
    show: boolean, chapter: ExtendedNovelsChapter, previous: PrevNextChapter | null, next: PrevNextChapter | null, progress: number | null, synced?: number | null
}) => {
    if (!show) return null;
    return <div className={"absolute top-0 w-full bg-gray-700 p-4 PageBody"}>
        <div className={"PageHeader"}>
            <ChapterInfo chapter={chapter} next={next} previous={previous}/>
            <div className={"flex justify-between pb-3"}>
                {synced ? <span>synced: {synced} %</span> : null}
                {progress ? <span>current: {progress} %</span> : null}
            </div>
        </div>
        <NovelsChapterList className={"flex-grow overflow-y-auto PageContent"}
                           chaptersPerPage={5}
                           fiction_id={chapter.fiction_id}
                           setExtraFields={() => null}/>
    </div>;
}

const ProgressButton = ({synced, chapterBody}: {
    synced: { progress: number | null } | null, chapterBody: HTMLDivElement | null
}) => {
    if (synced === null || synced.progress === null) return null;
    return <button
        className={"py-2.5 px-3 sm:pl-5 whitespace-nowrap" + (synced.progress === 100 ? ' bg-green-900' : ' bg-yellow-900')}
        onClick={() => restorePosition(synced.progress, chapterBody)}>
        <IoCaretDown className={"inline-block mr-1"}/>{synced.progress} %</button>
}

const ForceButton = ({
                         progress, setSynced, chapterId, equal
                     }: { progress: number, chapterId: number, setSynced: CallableFunction, equal: boolean }) => {
    return <button
        className={"py-2.5 px-3 sm:pl-5 whitespace-nowrap" + (progress === 100 || equal ? ' bg-green-900 ' : ' ')}
        onClick={() => backend.reportReadingProgress({readingProgress: progress, chapterId})
            .then((rsp) => setSynced({chapterId: rsp.chapter_id, progress: rsp.reading_progress}))
        }>
        <IoCaretUp className={"inline-block mr-1"}/>{progress} %
    </button>
}

export const NovelsChapterDetail = ({setExtraFields}: { setExtraFields: CallableFunction }) => {
    const {chapter_id} = useParams<{ chapter_id?: string }>();
    const [request, setRequest] = useState<NovelsChapterDetailResponse | undefined>();
    const [progress, setProgress] = useState<number | null>(null);
    const [synced, setSynced] = useState<{ chapterId: number, progress: number | null } | null>(null);
    const [infoPopover, setInfoPopover] = useState<boolean>(false);
    const chapterBody = useRef<HTMLDivElement>(null);

    useEffect(() => {
        chapterBody?.current?.scrollTo({top: 0})
        setProgress(0)
        if (chapter_id) {
            backend.loadNovelsChapterDetail({chapter_id}).then(f => {
                setSynced({chapterId: f.chapter.chapter_id, progress: f.chapter.reading_progress})
                setRequest(f);
            });
        }
    }, [chapter_id])

    useEffect(() => {
        if (request?.chapter.chapter_content) setTimeout(() => styleTables());
    }, [request])

    useEffect(() => {
        if (!!request?.chapter) {
            setExtraFields(<div className={"flex sm:flex-col-reverse"}>
                {(progress || 0) !== (synced?.progress || 0)
                    ? <ForceButton progress={progress || 0} chapterId={request.chapter.chapter_id} setSynced={setSynced}
                                   equal={(progress || 0) === (synced?.progress || 0)}/>
                    : null
                }
                {(synced?.progress || 0) > (progress || 0)
                    ? <ProgressButton synced={synced} chapterBody={chapterBody.current}/>
                    : null
                }
                {(synced?.progress || 0) === (progress || 0)
                    ? <div
                        className={"py-3 px-3 sm:pl-5 whitespace-nowrap" + (progress === 100 ? " bg-green-900" : '')}>{progress} %</div>
                    : null
                }
                <button onClick={() => setInfoPopover(!infoPopover)}
                        className={"py-2.5 px-4 sm:pl-5 bg-action w-full whitespace-nowrap"}>
                    <IoBook className={"inline-block"}/><span className={"hidden sm:inline"}> info</span>
                </button>
            </div>);
        }
        const interval = setInterval(() => {
            setProgress(getScrollPosition(chapterBody.current))
        }, 1000)
        return () => {
            clearInterval(interval);
            setExtraFields(null);
        }
    }, [request, setExtraFields, progress, chapterBody, infoPopover, synced])
    useEffect(() => {
        if (!chapter_id) return;
        if (synced?.chapterId?.toString() !== chapter_id) return;
        if (!progress) return;
        if (progress === synced?.progress) return;
        if (progress === 100 || (synced?.progress || 0) + 10 < progress) {
            backend.reportReadingProgress({
                // @ts-ignore
                chapterId: synced.chapterId, readingProgress: progress
            }).then(json => {
                if (json.chapter_id.toString() === chapter_id && json.reading_progress) setSynced({
                    progress: json.reading_progress,
                    chapterId: json.chapter_id
                })
            })
        }
        return () => {
            if (synced === null || synced.progress === null) return;
            if (progress > synced.progress) {
                backend.reportReadingProgress({
                    // @ts-ignore
                    chapterId: synced.chapterId, readingProgress: progress
                }).then()
            }
        }
    }, [progress, synced, chapter_id])


    if (!request) return <div>...loading...</div>

    return <div className={"max-w-2xl mx-auto relative PageBody"}>
        <InfoPopover show={infoPopover} chapter={request.chapter} next={request.next} previous={request.previous}
                     progress={progress} synced={synced?.progress}/>
        <div className={"overflow-y-scroll PageContent sm:h-screen sm:max-h-screen"} ref={chapterBody}>
            <ChapterInfo chapter={request.chapter}/>
            <article className={"prose prose-lg break-words mt-4 ChapterBody"}
                     dangerouslySetInnerHTML={{__html: request.chapter.chapter_content || ''}}/>
            <div className={"mt-3"}><span
                className={"py-2 bg-gray-700 px-4 w-full inline-block" + (synced?.progress === 100 ? " bg-green-900" : '')}>{request.chapter.chapter_title}</span>
            </div>
            {request.next !== null
                ?
                <Link
                    className={'py-2 bg-gray-900 flex justify-center items-center underline px-2 ' + (request.next.reading_progress ? 'bg-green-900' : '')}
                    to={novelsChapterDetailsUrl(request.next.chapter_id)}>
                    <IoCaretForwardOutline className={"w-8"}/>
                    <span className={"flex-grow"}>{request.next.chapter_title}</span>
                </Link>
                : null}
        </div>
    </div>
}

import {useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import {backend} from "../../requests/backend.service";
import {
    NovelsChapter,
    NovelsChaptersByFictionIdResponse,
    NovelsFiction,
    NovelsFictionDetailsResponse
} from "../../requests/requests.interface";
import {AuthorLink, ChapterLink} from "../../components/DetectionLinks";
import {ButtonPagination, IndexPagination} from "../../components/pagination";
import {ReadingProgressIndicator} from "../../components/ReadingProgressIndicator";
import {strDateFormatDAY} from "../../components/Timestamp";


const ChapterCard = ({chapter}: { chapter: NovelsChapter }) => {
    return <div className={"flex flex-col"}>
        <div
            className={"px-2 my-1 flex items-center" + (chapter.reading_progress === 100 ? ' bg-green-900' : ' bg-gray-700')}>
            <ChapterLink className={"flex-grow py-2"} chapter={chapter}/>
            {chapter.reading_progress
                ? <ReadingProgressIndicator progress={chapter.reading_progress}/>
                : <span className={"text-xs text-gray-300"}>{strDateFormatDAY(chapter.published_ts)}</span>}
        </div>
    </div>
}
export const NovelsChapterList = ({
                                      fiction_id,
                                      chapters,
                                      chaptersPerPage = 25,
                                      className = '',
                                      setExtraFields = () => null
                                  }: {
    fiction_id?: string | number, chapters?: NovelsChaptersByFictionIdResponse, setExtraFields?: CallableFunction, className?: string
    chaptersPerPage?: number
}) => {
    const [chaptersRequest, setChaptersRequest] = useState<NovelsChaptersByFictionIdResponse | undefined>()
    const [pages, setPages] = useState<{ number: number, current: number } | null>(null)
    const [shownChapters, setShownChapters] = useState<NovelsChapter[] | null>()

    useEffect(() => {
        if (fiction_id && !chapters) {
            backend.loadNovelsFictionChapters({fiction_id}).then(c => setChaptersRequest(c))
        } else if (!!chapters) {
            setChaptersRequest(chapters);
        }
    }, [fiction_id, chapters])
    useEffect(() => {
        const json = chaptersRequest?.results
        if (!json) return;
        const firstUnread = json.findIndex(c => c.reading_progress !== 100);
        const totalPages = Math.ceil(json.length / chaptersPerPage)
        const page = firstUnread === -1
            ? totalPages
            : Math.floor(firstUnread / chaptersPerPage) + 1
        setPages({current: page, number: totalPages})
    }, [chaptersRequest, chaptersPerPage])
    useEffect(() => {
        if (!chaptersRequest?.results) return;
        if (!pages) return;
        const lastElement = Math.min(pages.current * chaptersPerPage, chaptersRequest.results.length);
        const firstElement = Math.max(lastElement - chaptersPerPage, 0);
        setShownChapters(chaptersRequest.results.slice(firstElement, lastElement));
        setExtraFields(<ButtonPagination
            next={pages.current < pages.number
                ? () => setPages({
                    number: pages.number,
                    current: pages.current + 1
                })
                : null}
            back={pages.current > 1
                ? () => setPages({
                    number: pages.number,
                    current: pages.current - 1
                })
                : null}/>)
        return () => setExtraFields(null)
    }, [pages, chaptersRequest, setExtraFields, chaptersPerPage])

    if (!chapters && !chaptersRequest) return <div>...loading...</div>
    return <div className={className + " flex flex-col"}>
        {!!pages ? <IndexPagination className={"mb-0.5"}
                                    total={pages.number} current={pages.current}
                                    activate={(i: number) => setPages({current: i, number: pages.number})}/> : null}
        <div className={"flex-grow overflow-y-auto"}>
            {shownChapters?.map(c => <ChapterCard key={c.chapter_id} chapter={c}/>)}
        </div>
    </div>
}

const FictionHeader = ({fiction, className = ''}: { fiction: NovelsFiction, className?: string }) => {
    return <div className={"bg-gray-700 p-4 text-center " + className}>
        <h1 className={"my-2"}>{fiction.fiction_title}</h1>
        <h4><AuthorLink prefix={<span className={"text-sm text-gray-300"}>by </span>} author={fiction}/></h4>
    </div>
}

export const NovelsFictionDetail = ({setExtraFields}: { setExtraFields: CallableFunction }) => {
    const {fiction_id} = useParams<{ fiction_id?: string }>()
    const [fictionRequest, setFictionRequest] = useState<NovelsFictionDetailsResponse | undefined>()
    const [chaptersRequest, setChaptersRequest] = useState<NovelsChaptersByFictionIdResponse | undefined>()

    useEffect(() => {
        if (fiction_id) {
            backend.loadNovelsFictionDetail({fiction_id}).then(f => setFictionRequest(f));
            backend.loadNovelsFictionChapters({fiction_id}).then(json => {
                setChaptersRequest(json)
            })
        }
    }, [fiction_id])

    if (!fictionRequest) return <div>...loading...</div>
    return <div className={"PageBody max-w-2xl w-full mx-auto flex flex-col P "}>
        <FictionHeader className={"PageHeader"} fiction={fictionRequest.fiction}/>
        <NovelsChapterList className={"PageContent"} chapters={chaptersRequest} setExtraFields={setExtraFields}/>
    </div>
}

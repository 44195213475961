export const getScrollPosition = (e: HTMLDivElement | null): null | number => {
    if (e === null) return null;
    if (e.scrollTop !== 0) {
        const totalHeight = e.scrollHeight;
        const currentPosition = e.scrollTop + e.clientHeight;
        return Math.round((currentPosition / totalHeight) * 100);
    } else if (e.scrollHeight <= e.clientHeight) return 100;
    return 0;
}

export const restorePosition = (position: null | number, e: HTMLDivElement | null): void => {
    if (!e) return;
    if (!position) return;
    e.scrollTo({top: (e.scrollHeight / 100 * position) - e.clientHeight});
}

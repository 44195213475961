export const fetchesListUrl = (qParams = "") => `/scrapes/fetches${qParams}`
export const fetchesDetailsUrl = (id: string|number) => `/scrapes/fetches/${id}`

export const detectionsFictionSearchUrl = () => '/detections/fiction/search';

export const detectionsFictionDetailUrl = (id: string|number) => `/detections/fiction/${id}`
export const detectionsAuthorDetailUrl = (id: string|number) => `/detections/author/${id}`

export const detectionsChapterDetailUrl = (id: string|number) => `/detections/chapter/${id}`
export const novelsAuthorDetailsUrl = (id: string|number) => `/novels/author/${id}`;

export const novelsChapterDetailsUrl = (id: string|number) => `/novels/chapter/${id}`;
export const novelsFictionDetailsUrl = (id: string|number) => `/novels/fiction/${id}`;
export const novelsFictionListUrl = (qParams = "") => `/novels/fictions${qParams}`;

export const profilesProfileUrl = () => '/profile';

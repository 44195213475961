export const ENVIRONMENT = (() => {
    return (window as any).ENV ?? {
        API: process.env.REACT_APP_API || "",
        AUTH: {
            redirect_uri: process.env.REACT_APP_AUTH_REDIRECT_URI || "",
            client_id: process.env.REACT_APP_AUTH_CLIENT_ID || "",
        },
        TOKEN_URL: process.env.REACT_APP_AUTH_TOKEN_URL || "",
        AUTH_URL: process.env.REACT_APP_AUTH_AUTH_URL || "",
        LOGOUT_URL: process.env.REACT_APP_LOGOUT_URL || ""
    }
})()

import {
    ExtendedDetectedAuthorData,
    ExtendedDetectedChapterData,
    ExtendedDetectedFictionData
} from "../requests/requests.interface";
import {Link} from "react-router-dom";
import {
    detectionsAuthorDetailUrl,
    detectionsChapterDetailUrl,
    detectionsFictionDetailUrl
} from "../requests/urls.const";
import React, {useState} from "react";
import {FullTimeFormat} from "./Timestamp";
import {IoCaretForward} from "@react-icons/all-files/io5/IoCaretForward";
import {IoCaretDown} from "@react-icons/all-files/io5/IoCaretDown";
import {SourceEnum} from "../requests/Source.enum";


const SourceInformation = (props: { source: SourceEnum }) => <div>
    <span className="slight-offset-color font-smaller">from: </span>
    <span>{props.source}</span>
</div>

const ConcatText = ({text, concat}: { text: string, concat: boolean }) => <span
    className={concat ? "w-80 flex-grow text-left whitespace-nowrap overflow-hidden overflow-ellipsis" : ''}>
    {text}
</span>

const DetectionLine = ({
                           text,
                           concat,
                           setExpanded
                       }: { text: string, concat: boolean, setExpanded: CallableFunction }) => {
    return <button className={"flex w-full items-center"} onClick={() => setExpanded(concat)}>{concat
        ? <IoCaretForward className="w-8" title="open details"/>
        : <IoCaretDown className="w-8" title="close details"/>}
        <ConcatText text={text} concat={concat}/>
    </button>
}


export function DetectedFictionDataDetail(props: { fiction: ExtendedDetectedFictionData }) {
    const [expanded, setExpanded] = useState<boolean>(false);

    const details = expanded ? <div className="pl-8">
        <SourceInformation source={props.fiction.source}/>
        <div>
            <span className="slight-offset-color font-smaller">detected: </span>
            <Link className={'underline'} to={detectionsFictionDetailUrl(props.fiction.detected_fiction_id)}>
                <FullTimeFormat dateString={props.fiction.detected_ts}/>
            </Link>
        </div>
    </div> : null
    return <div className={"flex flex-col w-full max-w-2xl"}>
        <DetectionLine text={props.fiction.fiction_title} concat={!expanded} setExpanded={setExpanded}/>
        {details}
    </div>
}

export function DetectedFictionDataList(props: { detections: Array<ExtendedDetectedFictionData> | null }) {
    const [expanded, setExpanded] = useState<boolean>(props?.detections?.length === 1);
    if (!props.detections) return null

    const detections = expanded
        ? props.detections.map(f => <DetectedFictionDataDetail key={f.fiction_parse_id} fiction={f}/>)
        : null
    return <div className="w-full max-w-2xl">
        <button onClick={() => setExpanded(!expanded)}>fictions ({props.detections.length})</button>
        {detections}
    </div>
}

export function DetectedChapterDataDetail(props: { chapter: ExtendedDetectedChapterData }) {
    const [expanded, setExpanded] = useState<boolean>(false);
    const details = expanded ? <div className="">
        <SourceInformation source={props.chapter.source}/>
        <div>
            <span className="slight-offset-color font-smaller">fiction: </span>
            <Link className={'underline'}
                  to={detectionsFictionDetailUrl(props.chapter.detected_fiction_id)}>{props.chapter.fiction_title}</Link>
        </div>
        <div>
            <span className="slight-offset-color font-smaller">detected: </span>
            <Link className={'underline'}
                  to={detectionsChapterDetailUrl(props.chapter.detected_chapter_id)}><FullTimeFormat
                dateString={props.chapter.detected_ts}/></Link>
        </div>
    </div> : null

    return <div className={"max-w-2xl w-full"}>
        <DetectionLine text={props.chapter.chapter_title} concat={!expanded} setExpanded={setExpanded}/>
        {details}
    </div>
}

export function DetectedChapterDataList(props: { detections: Array<ExtendedDetectedChapterData> | null }) {
    const [expanded, setExpanded] = useState<boolean>(props?.detections?.length === 1);
    if (!props.detections) return null
    const detections = props.detections.map(c => <DetectedChapterDataDetail key={c.chapter_parse_id} chapter={c}/>)
    return <div className="max-w-2xl w-full">
        <button onClick={() => setExpanded(!expanded)}>chapters ({props.detections.length})</button>
        {expanded ? detections : null}
    </div>
}

export function DetectedAuthorDataDetail(props: { author: ExtendedDetectedAuthorData }) {
    const [expanded, setExpanded] = useState<boolean>(false);
    const details = expanded ? <div className="pl-8">
        <SourceInformation source={props.author.source}/>
        <div>
            <span className="slight-offset-color font-smaller">detected: </span>
            <Link className={'underline'} to={detectionsAuthorDetailUrl(props.author.detected_author_id)}>
                <FullTimeFormat dateString={props.author.detected_ts}/>
            </Link>
        </div>
    </div> : null;
    return <div className={''}>
        <DetectionLine text={props.author.name} concat={!expanded} setExpanded={setExpanded}/>
        {details}
    </div>
}

export function DetectedAuthorDataList(props: { detections: Array<ExtendedDetectedAuthorData> | null }) {
    const [expanded, setExpanded] = useState<boolean>(props?.detections?.length === 1);
    if (!props.detections) return null
    const detections = expanded
        ? props.detections.map(a => <DetectedAuthorDataDetail key={a.author_parse_id} author={a}/>)
        : null;
    return <div className="w-full max-w-2xl">
        <button onClick={() => setExpanded(!expanded)}>authors ({props.detections.length})</button>
        {detections}
    </div>
}


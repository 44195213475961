import React, {useEffect, useState} from "react";
import {
    NovelsChapter,
    NovelsChaptersByFictionIdResponse,
    NovelsFiction,
    NovelsFictionListResponse
} from "../../requests/requests.interface";
import {backend} from "../../requests/backend.service";
import {Link, useLocation} from "react-router-dom";
import {AuthorLink} from "../../components/DetectionLinks";
import {objectToQuery} from "../../helpers/ObjectToQuery";
import {novelsChapterDetailsUrl, novelsFictionDetailsUrl, novelsFictionListUrl} from "../../requests/urls.const";
import {Pagination} from "../../components/pagination";
import {IoCaretDown} from "@react-icons/all-files/io5/IoCaretDown";
import {IoCaretUp} from "@react-icons/all-files/io5/IoCaretUp";
import {Spinner} from "../../components/spinner";
import {ReadingProgressIndicator} from "../../components/ReadingProgressIndicator";
import {strDateFormatDAY} from "../../components/Timestamp";


const FictionPreview = ({
                            fiction,
                            unreadChapters,
                            setExpanded
                        }: { fiction: NovelsFiction, unreadChapters: number, setExpanded: CallableFunction }) => {
    const [chapters, setChapters] = useState<NovelsChaptersByFictionIdResponse>()
    const [showChapters, setShowChapters] = useState<NovelsChapter[]>()
    const [canShowMore, setCanShowMore] = useState<boolean>(true);
    useEffect(() => {
        backend.loadNovelsFictionChapters({fiction_id: fiction.fiction_id})
            .then((json) => {
                setChapters(json)
                if (json.results === null) {
                    setShowChapters([])
                    return
                }
                const firstUnread = json.results.findIndex(c => c.reading_progress !== 100);
                const firstShown = (() => {
                    if (firstUnread === -1) {
                        return Math.max(0, json.results.length - 2)
                    }
                    return Math.max(0, firstUnread - 1);
                })()
                const lastShown = Math.min(firstShown + 3, json.results.length);
                if (lastShown === json.results.length) setCanShowMore(false);
                setShowChapters(json.results.slice(firstShown, lastShown))
            })
    }, [fiction])
    const showMore = () => {
        if (!chapters || !showChapters) return
        const firstUnread = Math.max((chapters.results.findIndex(c => c.reading_progress !== 100) - 1), 0);
        const currentLength = showChapters.length
        const lastShown = Math.min(currentLength + 10, chapters.results.length);
        if (lastShown === chapters.results.length) setCanShowMore(false);
        setShowChapters(chapters.results.slice(firstUnread, lastShown))
    }
    return <div className={"pt-2"}>
        <div className={"text-center"}><span className={"text-gray-300 text-sm"}>by</span> <AuthorLink
            author={fiction}/></div>
        <div className={"py-2 flex justify-around"}>
            <span>chapters: {fiction.chapter_count}</span>
            {unreadChapters > 0 ? <span>unread: {unreadChapters}</span> : null}
        </div>
        <div>
            {showChapters !== undefined
                ? showChapters?.map(c => {
                    return <div
                        className={"py-1 px-2 m-1 flex items-center" + (c.reading_progress === 100 ? ' bg-green-900' : ' bg-gray-700')}
                        key={c.chapter_id}>
                        <Link className={"overflow-hidden whitespace-nowrap overflow-ellipsis flex-grow"}
                              to={novelsChapterDetailsUrl(c.chapter_id)}>{c.chapter_title}</Link>
                        {c.reading_progress ? <ReadingProgressIndicator progress={c.reading_progress}/> :
                            <span className={"text-xs text-gray-300"}>{strDateFormatDAY(c.published_ts)}</span>}
                    </div>
                })
                : <div className={"h-24 flex items-center justify-center"}><Spinner/></div>
            }
        </div>
        <div className={"flex py-2"}>
            {canShowMore
                ? <button className={"flex-grow text-center text-sm text-gray-300"} onClick={() => showMore()}>show
                    more</button>
                : <div className={"flex-grow"}/>}
            <IoCaretUp className={"ml-2 px-2 w-4 h-4 min-w-min "} onClick={() => setExpanded(false)}/>
        </div>
    </div>
}
const UnreadChaptersPreview = ({unreadChapters, expanded}: { unreadChapters: number, expanded: boolean }) => {
    if (!unreadChapters || expanded) return <span className={"w-5 text-xs text-gray-300 px-1"}/>;
    return <span
        className={"w-5 text-xs text-gray-300 text-left px-1"}>{unreadChapters > 9 ? '9+' : unreadChapters}</span>
}

const FictionCard = ({fiction}: { fiction: NovelsFiction }) => {
    const [expanded, setExpanded] = useState<boolean>(false)
    const unreadChapters = fiction.chapter_count - (fiction.read_chapters || 0)
    const rounded = expanded ? ' rounded-t' : '';
    const titleBackground = fiction.chapter_count === (fiction.read_chapters || 0) ? ' bg-green-900' : ' bg-gray-700'
    const titleConcat = expanded ? ' text-center' : ' overflow-hidden  whitespace-nowrap overflow-ellipsis'
    return <div
        className={"transition-height m-2 bg-gray-900 " + rounded}>
        <h4 className={"py-2.5 px-1 flex items-center" + rounded + titleBackground}>
            {expanded ? null : <UnreadChaptersPreview expanded={expanded} unreadChapters={unreadChapters}/>}
            <Link className={"px-1 flex-grow flex items-center " + titleConcat}
                  to={novelsFictionDetailsUrl(fiction.fiction_id)}>
                <div className={titleConcat + " flex-grow"}>
                    <span className={"flex-1 overflow-hidden"}>{fiction.fiction_title}</span>
                </div>
                {expanded ? null :
                    <span className={"pl-1.5 whitespace-nowrap text-xs text-gray-300"}>{fiction.author_name}</span>}
            </Link>
            {expanded
                ? null
                : <IoCaretDown className={"ml-0.5 px-1 w-4 h-4 min-w-min text-gray-300"}
                               onClick={() => setExpanded(!expanded)}/>
            }
        </h4>
        {expanded
            ? <FictionPreview fiction={fiction} unreadChapters={unreadChapters} setExpanded={setExpanded}/>
            : null
        }
    </div>
}


export const NovelsFictionList = ({setExtraFields}: { setExtraFields: CallableFunction }) => {

    const [response, setResponse] = useState<NovelsFictionListResponse>();

    const location = useLocation();
    useEffect(() => {
        backend.loadNovelsFictionList({query: location.search})
            .then(response => {
                const params = new URLSearchParams(window.location.search.substr(1));
                const pages = {
                    next: response.total_fictions > (response.limit + response.offset)
                        ? objectToQuery({
                            orderBy: (params.get("orderBy") || 'last_read'),
                            offset: Math.min(response.offset + response.limit, response.total_fictions - response.limit).toString()
                        })
                        : null,
                    back: response.offset > 0
                        ? objectToQuery({
                            orderBy: (params.get("orderBy") || 'last_read'),
                            offset: Math.max(response.offset - response.limit, 0).toString()
                        })
                        : null
                }
                setExtraFields(<Pagination next={pages.next} back={pages.back} destination={novelsFictionListUrl}/>)

                setResponse(response)
            })
        return () => setExtraFields(null)
    }, [location, setExtraFields])

    if (!response) return <div>loading...</div>
    return <div className={"PageBody"}>
        <div className={"PageContent max-w-2xl w-full mx-auto"}>
            {(response.results || []).map(fiction =>
                <FictionCard key={fiction.fiction_id} fiction={fiction}/>
            )}
        </div>
    </div>
}
